<template>
  <v-sheet
    color="grey lighten-4"
    class="pa-2"
  >
    <v-row>
      <v-col
        class="text-left"
      >
        <v-btn
          small
          text
          outlined
          color="success"
          @click="openDialogItem('ROW')"
        >
          <v-icon
            v-text="'mdi-table-row-plus-after'"
            left
          />
          Adicionar Linha
        </v-btn>
        <v-btn
          small
          text
          outlined
          color="success"
          class="ml-2"
          @click="openDialogItem('COLUMN')"
        >
          <v-icon
            v-text="'mdi-table-column-plus-after'"
            left
          />
          Adicionar Coluna
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-simple-table
          dense
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th></th>
                <th
                  v-for="(column, index) in schema.columns" 
                  class="text-left"
                  :key="index"
                >
                  {{ column.title }}

                  <v-btn
                    small
                    icon
                    @click="removeItem('COLUMN', index)"
                  >
                    <v-icon v-text="'mdi-delete'" />
                  </v-btn>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(row, index) in schema.rows"
                :key="index"
              >
                <td>
                  {{ row.title }}

                  <v-btn
                    small
                    icon
                    @click="removeItem('ROW', index)"
                  >
                    <v-icon v-text="'mdi-delete'" />
                  </v-btn>
                </td>
                <td :colspan="schema.columns.length" ></td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>

    <dialog-question-table-fields
      v-model="showDialogItem"
      :type="typeDialogItem"
      @insert="addItem"
    />
  </v-sheet>
</template>

<script>

  export default {

    components: {
      DialogQuestionTableFields: () => import('@/components/dialog/questions/DialogQuestionTableFields'),
    },

    props: {
      value: {
        type: Object,
        default: () => {
          return  {
            rows: [],
            columns: [],
          }
        },
      },
      surveyId: {
        type: Number,
        default: 0,
      },
    },

    data () {
      return {
        showDialogItem: false,
        typeDialogItem: null,
      }
    },

    computed: {
      schema: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        },
      },

    },

    methods: {
      addItem (item, type) {
        if (type === 'ROW') {
          if (typeof this.schema.rows === 'undefined') {
            this.schema.rows = []
          }

          this.schema.rows.push(item)
        } else if (type === 'COLUMN') {
          if (typeof this.schema.columns === 'undefined') {
            this.schema.columns = []
          }

          this.schema.columns.push(item)
        }

      },

      removeItem (type, index) {
        if (type === 'ROW') {
          this.schema.rows.splice(index, 1)
        } else if (type === 'COLUMN') {
          this.schema.columns.splice(index, 1)
        }
      },

      openDialogItem (type) {
        this.showDialogItem = true
        this.typeDialogItem = type
      }
    }

  }
</script>
